import React from "react";
import { Formik, Form } from "formik";
import ContentFold from "../page-content/content-fold";
import NewFormDate from "./NewFormDate";
import moment from "moment";
import { getInitialValues } from "./services/dateDemoInitialValues";

const DateDemo = () => {
  return(
    <ContentFold title="New Form Date Demo" isCentered>
      <Formik
        initialValues={getInitialValues()}
      >
        { ({ values, setFieldValue, handleChange }) => {
          return(
            <Form>
              <NewFormDate
                name="birthdayDemo"
                label="Birthday"
                helper={"config: {\n\tbirthday: true\n}"}
                values={values.birthdayDemo}
                setFieldValue={setFieldValue}
                onChange={handleChange}
                config={{
                  birthday: true
                }}
              />
              <NewFormDate
                name="futureDemo"
                label="Future Dates"
                helper={"config: {\n\tfuture: true\n}"}
                values={values.futureDemo}
                setFieldValue={setFieldValue}
                onChange={handleChange}
                config={{
                  future: true
                }}
              />
              <NewFormDate
                name="pastDemo"
                label="Past Dates"
                helper={"config: {\n\tpast: true\n}"}
                values={values.pastDemo}
                setFieldValue={setFieldValue}
                onChange={handleChange}
                config={{
                  past: true
                }}
              />
              <NewFormDate
                name="rangeDemo"
                label="Range of Dates: 2021-01-13 to 2023-05-12"
                helper={"config: {\n\trange: {\n\t\tminDate: \"2021-01-13\",\n\t\tmaxDate: \"2023-05-12\"\n\t}\n}"}
                values={values.rangeDemo}
                setFieldValue={setFieldValue}
                onChange={handleChange}
                config={{
                  range: {
                    minDate: "2021-01-13",
                    maxDate: "2023-05-12"
                  }
                }}
              />
              <NewFormDate
                name="prefDelDateDemo"
                label="Preferred  Delivery Date"
                helper={"config: {\n\tfuture: true,\n\tweekdays: true\n}"}
                values={values.prefDelDateDemo}
                setFieldValue={setFieldValue}
                onChange={handleChange}
                config={{
                  future: true,
                  weekdays: true,
                }}
              />
              <NewFormDate
                name="bmkProgramDemo"
                label="BM-K: 5 days from now until program end"
                helper={"config: {\n\trange: {\n\t\tminDate: moment().add(5, 'day').format(\"YYYY-MM-DD\"),\n\t\tmaxDate: \"2022-02-28\"\n\t}\n}"}
                values={values.bmkProgramDemo}
                setFieldValue={setFieldValue}
                onChange={handleChange}
                config={{
                  range: {
                    minDate: moment().add(5, 'day').format("YYYY-MM-DD"),
                    maxDate: "2022-02-28",
                  }
                }}
              />
              <NewFormDate
                name="validPrescriptionDemo"
                label="Valid Prescription: within the last year"
                helper={"config: {\n\trange: {\n\t\tmaxDate: moment().format(\"YYYY-MM-DD\"),\n\t\tincrement: \"year\",\n\t\tincrementValue: 1\n\t}\n}"}
                values={values.validPrescriptionDemo}
                setFieldValue={setFieldValue}
                onChange={handleChange}
                config={{
                  range: {
                    maxDate: moment().format("YYYY-MM-DD"),
                    increment: "years",
                    incrementValue: 1,
                  }
                }}
              />
            </Form>
          )
        }}
      </Formik>
    </ContentFold>
  )
};

export default DateDemo;