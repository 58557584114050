export const getInitialValues = () => {
  return {
    birthdayDemo: {
      month: {
        label: null,
        value: null,
      },
      date: {
        label: null,
        value: null,
      },
      year: {
        label: null,
        value: null,
      },
    },
    futureDemo: {
      month: {
        label: null,
        value: null,
      },
      date: {
        label: null,
        value: null,
      },
      year: {
        label: null,
        value: null,
      },
    },
    pastDemo: {
      month: {
        label: null,
        value: null,
      },
      date: {
        label: null,
        value: null,
      },
      year: {
        label: null,
        value: null,
      },
    },
    rangeDemo: {
      month: {
        label: null,
        value: null,
      },
      date: {
        label: null,
        value: null,
      },
      year: {
        label: null,
        value: null,
      },
    },
    prefDelDateDemo: {
      month: {
        label: null,
        value: null,
      },
      date: {
        label: null,
        value: null,
      },
      year: {
        label: null,
        value: null,
      },
    },
    bmkProgramDemo: {
      month: {
        label: null,
        value: null,
      },
      date: {
        label: null,
        value: null,
      },
      year: {
        label: null,
        value: null,
      },
    },
    validPrescriptionDemo: {
      month: {
        label: null,
        value: null,
      },
      date: {
        label: null,
        value: null,
      },
      year: {
        label: null,
        value: null,
      },
    }
  };
};