import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import DateDemo from "../components/forms/DateDemo";

const NewDates = () => {
  return(
    <Layout>
       <SEO
        title="New Date Config Demo"
       />
       <DateDemo />       
    </Layout>
  )
}

export default NewDates;